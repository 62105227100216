import { useUserContext } from "../../providers/UserProvider";
import { useSession } from "../useSession/useSession";
import { useOrganization } from "../useOrganization/useOrganization";
import { useLocation } from "react-router-dom";
import { useCallback, useMemo } from "react";
import { ANALYTICS_FLOWS, ANALYTICS_TRACKED_COMPONENTS } from "../../constants/analytics-constants";
import { GUID_PATTERN } from "../../constants/constants";

export type TrackingComponent = (typeof ANALYTICS_TRACKED_COMPONENTS)[keyof typeof ANALYTICS_TRACKED_COMPONENTS];

export type TrackingFlow = (typeof ANALYTICS_FLOWS)[keyof typeof ANALYTICS_FLOWS];

type TrackingData = {
    productName: string;
    subProductName: string;
    userId: string;
    groupId: string;
    isOptiUser: boolean;
    component: TrackingComponent;
    flow: TrackingFlow;
    path: string;
};

export const useAnalyticsTracking = () => {
    const { organizationId, organizationName, currentInstance, profile } = useUserContext();
    const { user } = useSession();
    const { organization, getOrganization } = useOrganization({ id: organizationId });
    const location = useLocation();

    const isOptiUser = useMemo(() => {
        return (
            user?.email?.toLowerCase().includes("@optimizely.com") ||
            user?.email?.toLowerCase().includes("@episerver.net") ||
            false
        );
    }, [user?.email]);

    const sendPageEvent = useCallback(() => {
        if (currentInstance?.productName && location?.pathname && organization?.crmId) {
            window.analytics.page(location.pathname.replace(GUID_PATTERN, "id"), {
                groupId: organization?.crmId,
                subProductName: currentInstance?.productName
            });
        }
    }, [organization?.crmId, location?.pathname, currentInstance?.productName]);

    const sendIdentifyEvent = useCallback(() => {
        if ((user?.email || profile) && organization?.crmId && currentInstance) {
            window.analytics.identify(user?.email || profile?.email, {
                userId: user?.email || profile?.email,
                groupId: organization?.crmId,
                organizationId,
                instanceId: currentInstance.groupId,
                instanceName: currentInstance.instanceName,
                name: `${profile?.firstName} ${profile?.lastName}`,
                isOptiUser
            });
        }
    }, [user?.email, currentInstance, isOptiUser, organization?.crmId, organizationId, profile]);

    const sendGroupEvent = useCallback(() => {
        if (organization?.crmId && currentInstance) {
            window.analytics.group(organization?.crmId, {
                groupId: organization?.crmId,
                organizationId,
                instanceId: currentInstance.groupId,
                instanceName: currentInstance.instanceName,
                name: organizationName,
                hasTurnstile: true
            });
        }
    }, [currentInstance, organization?.crmId, organizationId, organizationName]);

    const sendTrackEvent = useCallback(
        ({
            name,
            component,
            flow,
            data
        }: {
            name: string;
            component: TrackingComponent;
            flow: TrackingFlow;
            data: any;
        }) => {
            const trackingData: TrackingData = {
                productName: "Admin Center",
                subProductName: "Admin Center",
                userId: user?.email || profile?.email,
                groupId: organization?.crmId,
                isOptiUser,
                component,
                flow,
                path: location.pathname,
                ...data
            };

            window.analytics.track(name, trackingData);
        },
        [isOptiUser, location.pathname, organization?.crmId, profile?.email, user?.email]
    );

    const sendSessionEvent = useCallback(
        (name: string, email: string, organizationId: string) => {
            if (email && organizationId) {
                getOrganization({ id: organizationId })?.then((org) => {
                    sendTrackEvent({
                        name,
                        component: ANALYTICS_TRACKED_COMPONENTS.SESSION,
                        flow: ANALYTICS_FLOWS.LOGIN,
                        data: { userId: email, groupId: org?.crmId }
                    });
                });
            }
        },
        [getOrganization, sendTrackEvent]
    );

    return {
        sendTrackEvent,
        sendGroupEvent,
        sendIdentifyEvent,
        sendPageEvent,
        sendSessionEvent
    };
};
