export const ANALYTICS_EVENT_NAMES = {
    ADD_USER: "Add User",
    CREATE_CUSTOM_GROUP: "Create Custom Group",
    CREATE_CUSTOM_ROLE: "Create Custom Role",
    GRANT_PERMISSIONS: "Grant Permissions",
    REMOVE_USER: "Remove User",
    SESSION_LOGIN: "Authentication: Token Added",
    SESSION_RENEWED: "Authentication: Token Renewed"
};

export const ANALYTICS_TRACKED_COMPONENTS = {
    USER_SIDEBAR: "User Sidebar",
    USERS_TAB: "Users Tab",
    INVITATION_SIDEBAR: "Invitation Sidebar",
    UPDATE_GROUP_SIDEBAR: "Update Group Sidebar",
    CREATE_GROUP_SIDEBAR: "Create Group Sidebar",
    CREATE_CUSTOM_ROLE_SIDEBAR: "Create Custom Role Sidebar",
    SESSION: "Session"
} as const;

export const ANALYTICS_FLOWS = {
    USER_PAGE: "User Page",
    PRODUCT_ACCESS: "Product Access",
    GROUP_ACCESS: "Group Access",
    ROLES_PAGE: "Roles Page",
    LOGIN: "Login"
} as const;
