import { Collection } from "../domain/Collection";
import { User } from "../domain/User";
import { UserGroup } from "../domain/UserGroup";
import { Role } from "../domain/Role";
import { ProductInstance } from "../domain/ProductInstance";
import { Products } from "../domain/Products";
import { UserInstancePermission } from "../rest-model/UserInstancePermission";
import { OrganizationProduct } from "../domain/OrganizationProduct";

export type DataList = Collection<any> | Collection<any>[] | Products | UserInstancePermission[];
export type ChangeEventData = {
    user?: Pick<User, "email" | "firstName" | "lastName">;
    group?: Pick<UserGroup, "id" | "name" | "description">;
    instance?: Pick<
        ProductInstance,
        "instanceId" | "nickname" | "technicalContactEmail" | "technicalContactName" | "optiIdEnabled"
    >;
    role?: Pick<Role, "id" | "name" | "description">;
};

const update = (
    list: any[],
    eventData: ChangeEventData,
    matcher: (item: any, eventData: ChangeEventData) => boolean,
    updater: (item: any) => any
) => {
    let matchingItems = list.filter((item) => matcher(item, eventData)) || [];
    matchingItems.forEach(updater);
    return list;
};

const emailMatcher = (item: { email: string }, eventData: ChangeEventData) => {
    return item.email === eventData?.user?.email;
};

const idMatcher = (item: { id: string }, eventData: ChangeEventData) => {
    return item.id === eventData.group?.id || item.id === eventData.role?.id;
};

export const updateUser = (eventData: ChangeEventData, dataList: DataList): DataList => {
    if (!eventData?.user) return dataList;

    let updatedList: DataList = dataList;
    if ((dataList as Collection<User>).items) {
        updatedList = {
            ...dataList,
            items: update((dataList as Collection<User>).items || [], eventData, emailMatcher, (item: User) => {
                item.firstName = eventData.user!.firstName;
                item.lastName = eventData.user!.lastName;
            })
        };
    } else if ((dataList as Collection<User>[]).length) {
        //this block handles infinite list (list of collections)
        updatedList = (dataList as Collection<User>[]).map((list: Collection<User>) => {
            return {
                ...list,
                items: update(list.items || [], eventData, emailMatcher, (item: User) => {
                    item.firstName = eventData.user!.firstName;
                    item.lastName = eventData.user!.lastName;
                })
            };
        });
    }

    return updatedList;
};

export const updateGroup = (eventData: ChangeEventData, dataList: DataList): DataList => {
    if (!eventData?.group) return dataList;
    return {
        ...dataList,
        items: update((dataList as Collection<UserGroup>).items || [], eventData, idMatcher, (item: UserGroup) => {
            item.name = eventData.group!.name;
            item.description = eventData.group!.description;
        })
    };
};

export const updateRole = (eventData: ChangeEventData, dataList: DataList): DataList => {
    if (!eventData?.role) return dataList;
    return {
        ...dataList,
        items: update((dataList as Collection<Role>).items || [], eventData, idMatcher, (item: Role) => {
            item.name = eventData.role!.name;
            item.description = eventData.role!.description;
        })
    };
};

export const updateInstance = (eventData: ChangeEventData, dataList: DataList): DataList => {
    if (!eventData.instance) return dataList;

    const products = dataList as Products;
    if (products.organizationProducts || products.productInstances) {
        return {
            ...dataList,
            organizationProducts: update(
                (dataList as Products).organizationProducts,
                eventData,
                (item: OrganizationProduct, eventData: ChangeEventData) => {
                    return item.instances.some((i) => i.id === eventData.instance?.instanceId);
                },
                (item: OrganizationProduct) => {
                    const inst = item.instances.find((i) => i.id === eventData.instance?.instanceId);
                    if (inst) {
                        inst.nickname = eventData.instance?.nickname || undefined;
                        inst.technicalContactEmail = eventData.instance?.technicalContactEmail;
                        inst.technicalContactName = eventData.instance?.technicalContactName;
                        inst.optiIdEnabled = eventData.instance?.optiIdEnabled;
                    }
                }
            ),
            productInstances: update(
                (dataList as Products).productInstances,
                eventData,
                (item: ProductInstance, eventData: ChangeEventData) => {
                    return item.instanceId === eventData.instance?.instanceId;
                },
                (item: ProductInstance) => {
                    item.nickname = eventData.instance?.nickname;
                    item.technicalContactEmail = eventData.instance?.technicalContactEmail;
                    item.technicalContactName = eventData.instance?.technicalContactName;
                    item.optiIdEnabled = eventData.instance?.optiIdEnabled;
                }
            )
        };
    } else if ((dataList as Array<UserInstancePermission>).length) {
        return update(
            dataList as UserInstancePermission[],
            eventData,
            (item: UserInstancePermission, eventData: ChangeEventData) => {
                return item.instanceId === eventData.instance?.instanceId;
            },
            (item: UserInstancePermission) => {
                item.nickname = eventData.instance?.nickname || undefined;
            }
        );
    } else {
        return dataList;
    }
};
