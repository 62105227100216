import { Collection } from "../../domain/Collection";

import { UserGroup } from "../../domain/UserGroup";
import { useServicesContext } from "../../providers/ServicesProvider";
import { usePaginatedList } from "../usePaginatedList/usePaginatedList";
import { DEFAULT_PAGE_SIZE, EVENT_TYPES, SWR_KEY_PREFIX } from "../../constants/constants";
import { useUpdateSync } from "../useUpdateSync/useUpdateSync";

export const useUserGroups = ({
    organizationId,
    query = "",
    useExactMatchSearch,
    pageSize = DEFAULT_PAGE_SIZE,
    groupType = "",
    groupTypes = []
}: {
    organizationId?: string;
    query?: string;
    useExactMatchSearch?: boolean;
    pageSize?: number;
    groupType?: string; // todo: deprecate after `hide_product_groups` feature flag is removed
    groupTypes?: string[];
}) => {
    const { userService } = useServicesContext();
    const { fireChangeEvent } = useUpdateSync();

    const { pageData, error, mutate, currentPage, setCurrentPage } = usePaginatedList<UserGroup>({
        key: organizationId
            ? `${SWR_KEY_PREFIX.GROUPS}/api/usergroups?${organizationId}&query=${query}&groupType=${
                  groupType || groupTypes
              }&useExactMatchSearch=${useExactMatchSearch}`
            : null,
        fetcher: (): Promise<Collection<UserGroup>> => {
            return userService.getUserGroups({
                organizationId: organizationId as string,
                query,
                useExactMatchSearch,
                size: pageSize,
                page: currentPage,
                groupType,
                groupTypes
            });
        }
    });

    const addUserGroup = async (newGroup: UserGroup): Promise<UserGroup> => {
        const results = await userService.addUserGroup(newGroup);

        pageData &&
            (await mutate({
                items: [...(pageData.items || []), newGroup],
                totalCount: pageData.totalCount + 1
            }));

        return results;
    };

    const updateUserGroup = async ({
        previousUserGroup,
        updatedUserGroup
    }: {
        previousUserGroup: UserGroup;
        updatedUserGroup: UserGroup;
    }): Promise<UserGroup> => {
        const results = await userService.updateUserGroup({
            userGroup: previousUserGroup,
            updatedUserGroup
        });

        if (
            previousUserGroup.name !== updatedUserGroup.name ||
            previousUserGroup.description !== updatedUserGroup.description
        ) {
            fireChangeEvent({
                type: EVENT_TYPES.UPDATE_GROUP,
                data: {
                    group: {
                        id: updatedUserGroup.id,
                        name: updatedUserGroup.name,
                        description: updatedUserGroup.description
                    }
                }
            });
        }

        //TODO: this will go away when new sync'd updates are live
        const itemToUpdate: UserGroup | undefined = pageData?.items.find((item) => item?.id === updatedUserGroup.id);

        const updateUserGroups = () => {
            itemToUpdate && (itemToUpdate.instancePermissions = updatedUserGroup.instancePermissions);
            itemToUpdate && (itemToUpdate.users = updatedUserGroup.users);

            pageData && mutate({ items: [...pageData.items], totalCount: pageData.totalCount });
        };

        pageData && updateUserGroups();

        return results;
    };

    const deleteUserGroup = async ({ userGroupId }: { userGroupId: string }) => {
        return await userService.deleteUserGroup({ userGroupId });
    };

    const addUserGroupUsers = async ({ userGroupId, userEmails }: { userGroupId: string; userEmails: string[] }) => {
        return await userService.addUserGroupUsers({ userGroupId, userEmails });
    };

    const deleteUserGroupUsers = async ({ userGroupId, userEmails }: { userGroupId: string; userEmails: string[] }) => {
        return await userService.deleteUserGroupUsers({ userGroupId, userEmails });
    };

    return {
        userGroups: pageData?.items,
        isLoading: !error && !pageData,
        isSearching: !error && !pageData && query.length > 2,
        error,
        currentPage,
        setCurrentPage,
        addUserGroup,
        deleteUserGroup,
        updateUserGroup,
        pageSize: pageSize,
        totalCount: pageData?.totalCount || 0,
        addUserGroupUsers,
        deleteUserGroupUsers
    };
};
